
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { useGoogleAuth } from '@/contexts/GoogleAuthContext';
import UserAccount from '@/components/UserAccount';
import GoogleAuth from '@/components/auth/GoogleAuth';
import { Skeleton } from '@/components/ui/skeleton';

const Account = () => {
  const { currentUser, isAuthenticated, loading } = useGoogleAuth();
  const [pageReady, setPageReady] = useState(false);
  const navigate = useNavigate();

  // Add a slight delay to ensure auth has time to initialize
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageReady(true);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  const showContent = pageReady && !loading;
  const showLoading = !pageReady || loading;

  console.log("Account page state:", { pageReady, loading, isAuthenticated, currentUser });

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="flex justify-between items-center mb-6">
        <Link to="/">
          <Button variant="outline" size="sm">
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </Button>
        </Link>
        <h1 className="text-2xl font-bold">Account</h1>
      </div>

      {showLoading && (
        <div className="space-y-4">
          <Skeleton className="h-[250px] w-full rounded-lg" />
        </div>
      )}

      {showContent && (
        <>
          {isAuthenticated ? <UserAccount /> : <GoogleAuth />}
        </>
      )}
    </div>
  );
};

export default Account;
