
import { useState } from 'react';
import { useGoogleAuth } from '@/contexts/GoogleAuthContext';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { User } from 'lucide-react';
import GoogleSignInButton from './GoogleSignInButton';
import UserCard from './UserCard';

const GoogleAuth = () => {
  const { currentUser, isAuthenticated, signInWithGoogle } = useGoogleAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    
    try {
      await signInWithGoogle();
    } catch (error: any) {
      // Error handling is done within the context
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    return <UserCard />;
  }

  return (
    <Card className="w-full max-w-md mx-auto shadow-lg">
      <CardHeader className="text-center">
        <CardTitle className="flex items-center justify-center gap-2">
          <User className="h-5 w-5" />
          User Account
        </CardTitle>
        <CardDescription>Sign in with Google to save your PC builds</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="w-full mt-4 space-y-3">
          <GoogleSignInButton onClick={handleGoogleSignIn} isLoading={isLoading} />
        </div>
      </CardContent>
    </Card>
  );
};

export default GoogleAuth;
