// Image fallback configuration module
import { getPlaceholderImage } from '@/lib/imageRegistry';
import presetBuildsData from '@/lib/presetBuilds';

// Export fallback images for other components to use
export const fallbackImages: Record<string, string> = {
  'default': 'https://images.unsplash.com/photo-1649972904349-6e44c42644a7',
  'pc-build': 'https://images.unsplash.com/photo-1587202372616-b43abea06c2a',
  'gaming-pc': 'https://images.unsplash.com/photo-1624705023195-63170bd9050d',
  'workstation-pc': 'https://images.unsplash.com/photo-1607292708259-81fbba8e3ee5',
  'content-creation-pc': 'https://images.unsplash.com/photo-1602837385569-08ac19ec83af',
  'streaming-pc': 'https://images.unsplash.com/photo-1625842268584-8f3296236761',
  'office-pc': 'https://images.unsplash.com/photo-1593640408182-31c70c8268f5',
  'budget-pc': 'https://images.unsplash.com/photo-1593640495253-23196b27a87f',
  'tech': 'https://images.unsplash.com/photo-1518770660439-4636190af475',
  'programming': 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6',
  'monitor': 'https://images.unsplash.com/photo-1588200908342-23b585c03e26'
};

// Get a fallback image URL for a specific category
export const getCategoryFallback = (category?: string, name?: string): string => {
  if (!category) return fallbackImages['default'];
  
  // If we have a name, try to match it with a specific PC build type
  if (name) {
    const lowerName = name.toLowerCase();
    if (lowerName.includes('gaming')) return fallbackImages['gaming-pc'];
    if (lowerName.includes('work') || lowerName.includes('office')) return fallbackImages['office-pc'];
    if (lowerName.includes('stream')) return fallbackImages['streaming-pc'];
    if (lowerName.includes('content') || lowerName.includes('creator')) return fallbackImages['content-creation-pc'];
    if (lowerName.includes('budget')) return fallbackImages['budget-pc'];
    if (lowerName.includes('station')) return fallbackImages['workstation-pc'];
  }
  
  // Check if we have a specific fallback for this category
  if (fallbackImages[category]) {
    return fallbackImages[category];
  }
  
  // For PC builds, rotate through different images based on a hash of the category/name
  if (category === 'pc-build') {
    const pcBuildOptions = [
      fallbackImages['pc-build'],
      fallbackImages['gaming-pc'],
      fallbackImages['workstation-pc'],
      fallbackImages['content-creation-pc'],
      fallbackImages['streaming-pc'],
      fallbackImages['office-pc']
    ];
    
    // Create a simple hash from the name or category to distribute images evenly
    const hash = (name || category).split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const index = hash % pcBuildOptions.length;
    
    return pcBuildOptions[index];
  }
  
  // Otherwise return the default fallback
  return fallbackImages['default'];
};

// Function to update a fallback image URL
export const updateFallbackImage = (category: string, newUrl: string): void => {
  if (!category) {
    throw new Error('Category is required');
  }
  
  // Store in localStorage for persistence between page refreshes
  try {
    const storedOverrides = JSON.parse(localStorage.getItem('fallbackImageOverrides') || '{}');
    storedOverrides[category] = newUrl;
    localStorage.setItem('fallbackImageOverrides', JSON.stringify(storedOverrides));
  } catch (error) {
    console.error('Failed to save fallback image override to localStorage:', error);
  }
  
  // Dispatch an event so other components can react to the change
  window.dispatchEvent(new CustomEvent('fallback-image-updated', { 
    detail: { category, url: newUrl } 
  }));
};

// Function to get PC build image
export const getPCBuildImage = (buildId: string): string => {
  try {
    // Try to get from localStorage first
    const storedImages = JSON.parse(localStorage.getItem('pcBuildImages') || '{}');
    if (storedImages[buildId]) {
      return storedImages[buildId];
    }
    
    // Find the preset build from imported data
    const presetBuild = presetBuildsData.find(build => build.id === buildId);
    if (presetBuild && presetBuild.imageUrl) {
      return presetBuild.imageUrl;
    }
    
    // Return a varied fallback image for PC builds based on build ID
    const pcBuildOptions = [
      fallbackImages['pc-build'],
      fallbackImages['gaming-pc'],
      fallbackImages['workstation-pc'],
      fallbackImages['content-creation-pc'],
      fallbackImages['streaming-pc'],
      fallbackImages['office-pc'],
      fallbackImages['budget-pc']
    ];
    
    // Create a hash from the buildId to assign a consistent but varied image
    const hash = buildId.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const index = hash % pcBuildOptions.length;
    
    return pcBuildOptions[index];
  } catch (error) {
    console.error('Failed to load PC build image:', error);
    return fallbackImages['pc-build'];
  }
};

// Function to save a PC build image URL
export const savePCBuildImage = (buildId: string, imageUrl: string): void => {
  try {
    // Get existing stored images
    const storedImages = JSON.parse(localStorage.getItem('pcBuildImages') || '{}');
    
    // Update with new image URL
    storedImages[buildId] = imageUrl;
    
    // Save back to localStorage
    localStorage.setItem('pcBuildImages', JSON.stringify(storedImages));
    
    // Dispatch an event for components to react to the change
    window.dispatchEvent(new CustomEvent('pc-build-image-updated', {
      detail: { buildId, url: imageUrl }
    }));
  } catch (error) {
    console.error('Failed to save PC build image to localStorage:', error);
  }
};
