
/**
 * Utility functions for validating image URLs
 */

/**
 * Check if a URL should immediately use a fallback image
 * @param url The URL to check
 * @param category Optional category to check against
 * @returns Boolean indicating if fallback should be used
 */
export function shouldUseImmediateFallback(url: string, category?: string): boolean {
  if (!url || url === '') return true;
  
  // Consider Unsplash images always valid
  if (url.includes('images.unsplash.com')) return false;
  
  // Skip fallback for local uploaded images - return false to indicate we should try to load these
  if (url.startsWith('/lovable-uploads/') || url.includes('/lovable-uploads/')) return false;
  if (url.startsWith('/models/')) return false;
  
  // Handle problematic URLs that are known to fail
  const problematicUrls = [
    '/_next/image',
    'cdn.mos.cms.futurecdn.net',
    'amazon.com/images/I/',
    'amazon.co.uk/images/',
    'labs.google/fx/tools/' // Add Google Labs URL pattern
  ];
  
  return problematicUrls.some(badUrl => url.includes(badUrl));
}

/**
 * Check if a URL has a valid image extension
 * @param url The URL to check
 * @returns Boolean indicating if the URL has a valid image extension
 */
export function hasValidImageExtension(url: string): boolean {
  if (!url) return false;
  
  const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg', '.avif'];
  return validExtensions.some(ext => url.toLowerCase().includes(ext));
}

/**
 * Get the active image URL, considering any locally edited versions
 * @param imageId The ID of the image
 * @param originalUrl The original URL of the image
 * @returns The active URL, which may be different from the original if edited
 */
export function getActiveImageUrl(imageId: string, originalUrl: string): string {
  if (!imageId || !originalUrl) return originalUrl || '';
  
  try {
    // Check if this image has been edited and stored in localStorage
    const storedImages = JSON.parse(localStorage.getItem('editedImages') || '{}');
    
    // Return the edited URL if it exists, otherwise the original
    return storedImages[imageId] || originalUrl;
  } catch (error) {
    console.error('Error retrieving edited image:', error);
    return originalUrl;
  }
}

/**
 * Check if an image has been edited/modified from its original URL
 * @param imageId The ID of the image to check
 * @returns Boolean indicating if the image has been edited
 */
export function hasBeenEdited(imageId: string): boolean {
  if (!imageId) return false;

  try {
    const storedImages = JSON.parse(localStorage.getItem('editedImages') || '{}');
    return !!storedImages[imageId];
  } catch (error) {
    console.error('Error checking if image has been edited:', error);
    return false;
  }
}

/**
 * Track an edited image by saving it to localStorage
 * @param imageId The ID of the image
 * @param originalUrl The original URL of the image
 * @param newUrl The new URL to use for the image
 * @param category Optional category for the image
 */
export function trackEditedImage(
  imageId: string, 
  originalUrl: string, 
  newUrl: string, 
  category?: string
): void {
  if (!imageId || !newUrl) return;

  try {
    // Get existing edited images
    const storedImages = JSON.parse(localStorage.getItem('editedImages') || '{}');
    
    // Add/update the edited image
    storedImages[imageId] = newUrl;
    
    // Save back to localStorage
    localStorage.setItem('editedImages', JSON.stringify(storedImages));
    
    // Dispatch an event for other components to react to
    window.dispatchEvent(
      new CustomEvent('image-edited', {
        detail: {
          id: imageId,
          originalUrl,
          currentUrl: newUrl,
          category
        }
      })
    );
  } catch (error) {
    console.error('Error tracking edited image:', error);
  }
}

/**
 * Reset an edited image back to its original URL
 * @param imageId The ID of the image to reset
 */
export function resetEditedImage(imageId: string): void {
  if (!imageId) return;

  try {
    // Get existing edited images
    const storedImages = JSON.parse(localStorage.getItem('editedImages') || '{}');
    
    // If the image ID exists in the stored data, remove it
    if (storedImages[imageId]) {
      delete storedImages[imageId];
      
      // Save back to localStorage
      localStorage.setItem('editedImages', JSON.stringify(storedImages));
      
      // Dispatch an event for other components to react to
      window.dispatchEvent(
        new CustomEvent('image-reset', {
          detail: { id: imageId }
        })
      );
    }
  } catch (error) {
    console.error('Error resetting edited image:', error);
  }
}

/**
 * Determine if the user's browser is likely to have CORS issues with image loading
 * @returns Boolean indicating if CORS issues are likely
 */
export function browserLikelyCorsRestricted(): boolean {
  return navigator.userAgent.includes('Safari') && 
         !navigator.userAgent.includes('Chrome');
}
