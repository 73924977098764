
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Check, Star } from 'lucide-react';
import { ComponentSpec } from './types';
import { formatPrice } from '@/utils/pcBuilder/formatUtils';
import ImageValidatorItem from '@/components/ImageValidator/ImageValidatorItem';

interface ComponentCardProps {
  component: ComponentSpec;
  isSelected: boolean;
  onClick: (component: ComponentSpec) => void;
}

const ComponentCard: React.FC<ComponentCardProps> = ({
  component,
  isSelected,
  onClick,
}) => {
  // Generate random rating for demo purposes
  const rating = component.rating || Math.floor(Math.random() * 2) + 3; // 3-5 star rating
  
  return (
    <Card 
      className={`cursor-pointer hover:shadow-md transition-shadow relative overflow-hidden
        ${isSelected ? 'border-primary border-2' : ''}
      `}
      onClick={() => onClick(component)}
    >
      {isSelected && (
        <div className="absolute top-2 right-2 bg-primary text-primary-foreground p-1 rounded-full z-10">
          <Check className="h-4 w-4" />
        </div>
      )}
      <CardContent className="p-4">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4">
            <div className="w-16 h-16 overflow-hidden flex-shrink-0">
              <ImageValidatorItem 
                src={component.image || component.imageUrl || ''} 
                alt={component.name}
                className="w-full h-full object-contain"
                category={component.type || 'component'}
              />
            </div>
            <div className="flex-1 min-w-0">
              <h4 className="text-sm font-medium line-clamp-2">{component.name}</h4>
              <p className="text-xs text-muted-foreground">{component.brand}</p>
              <div className="flex items-center mt-1">
                {[...Array(5)].map((_, i) => (
                  <Star 
                    key={i} 
                    className={`h-3 w-3 ${i < rating ? 'text-amber-500 fill-amber-500' : 'text-muted-foreground'}`} 
                  />
                ))}
                <span className="text-xs ml-1 text-muted-foreground">
                  ({component.reviewCount || Math.floor(Math.random() * 500) + 50})
                </span>
              </div>
            </div>
          </div>
          
          {/* Specifications */}
          <div className="text-xs space-y-1 border-t pt-2">
            {component.specs && Object.entries(component.specs).slice(0, 3).map(([key, value]) => (
              <div key={key} className="flex justify-between">
                <span className="text-muted-foreground capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
                <span>{value as string}</span>
              </div>
            ))}
          </div>
          
          {/* Price */}
          <div className="text-right">
            <span className="text-lg font-semibold">{formatPrice(component.price)}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ComponentCard;
