
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { toast } from 'sonner';

// Google Client ID 
const GOOGLE_CLIENT_ID = "876219964709-sacred-evening-453203-d4";

// Define the Google user type
export type GoogleUser = {
  id: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
};

type GoogleAuthContextType = {
  currentUser: GoogleUser | null;
  isAuthenticated: boolean;
  loading: boolean;
  signInWithGoogle: () => Promise<void>;
  logOut: () => Promise<void>;
};

const GoogleAuthContext = createContext<GoogleAuthContextType>({
  currentUser: null,
  isAuthenticated: false,
  loading: true,
  signInWithGoogle: async () => {},
  logOut: async () => {}
});

export const useGoogleAuth = () => useContext(GoogleAuthContext);

// Load Google API script
const loadGoogleScript = () => {
  return new Promise<void>((resolve, reject) => {
    if (document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => resolve();
    script.onerror = (error) => reject(error);
    document.body.appendChild(script);
  });
};

export const GoogleAuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<GoogleUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [googleLoaded, setGoogleLoaded] = useState(false);

  // Load Google API and check for stored auth on initial load
  useEffect(() => {
    const initGoogleAuth = async () => {
      try {
        await loadGoogleScript();
        setGoogleLoaded(true);
        
        // Check for stored user
        const storedUser = localStorage.getItem('googleUser');
        if (storedUser) {
          try {
            setCurrentUser(JSON.parse(storedUser));
          } catch (error) {
            console.error('Error parsing stored user:', error);
            localStorage.removeItem('googleUser');
          }
        }
      } catch (error) {
        console.error('Error loading Google API:', error);
      } finally {
        setLoading(false);
      }
    };

    initGoogleAuth();
  }, []);

  const signInWithGoogle = async () => {
    try {
      if (!googleLoaded || !window.google) {
        throw new Error('Google API not loaded');
      }

      return new Promise<void>((resolve, reject) => {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: async (response) => {
            try {
              if (!response.credential) {
                throw new Error('No credential returned from Google');
              }
              
              // Decode the JWT token from Google
              const payload = JSON.parse(atob(response.credential.split('.')[1]));
              
              const googleUser: GoogleUser = {
                id: payload.sub,
                email: payload.email,
                displayName: payload.name,
                photoURL: payload.picture
              };
              
              setCurrentUser(googleUser);
              localStorage.setItem('googleUser', JSON.stringify(googleUser));
              
              toast.success("Signed in with Google", {
                description: "Welcome to PC Builder!"
              });
              
              resolve();
            } catch (error: any) {
              console.error("Error processing Google sign in:", error);
              toast.error("Google sign in failed", {
                description: error.message || "Failed to process Google sign in"
              });
              reject(error);
            }
          },
          prompt_parent_id: 'google-signin-prompt'
        });
        
        window.google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            // Google One Tap is not displayed, fallback to regular sign in
            window.google.accounts.id.renderButton(
              document.getElementById('google-signin-button') as HTMLElement,
              { theme: 'outline', size: 'large', width: 280 }
            );
          }
        });
      });
    } catch (error: any) {
      console.error("Error signing in with Google:", error);
      toast.error("Google sign in failed", {
        description: error.message || "Failed to sign in with Google"
      });
      throw error;
    }
  };

  const logOut = async () => {
    try {
      if (window.google?.accounts?.id) {
        window.google.accounts.id.disableAutoSelect();
        
        // Only call revoke if we have a user ID
        if (currentUser?.id) {
          window.google.accounts.id.revoke(currentUser.id, () => {
            completeSignOut();
          });
        } else {
          completeSignOut();
        }
      } else {
        completeSignOut();
      }
    } catch (error: any) {
      console.error("Error signing out:", error);
      toast.error("Error", {
        description: "Failed to sign out"
      });
      throw error;
    }
  };
  
  const completeSignOut = () => {
    setCurrentUser(null);
    localStorage.removeItem('googleUser');
  };

  const value = {
    currentUser,
    isAuthenticated: !!currentUser,
    loading,
    signInWithGoogle,
    logOut
  };

  return (
    <GoogleAuthContext.Provider value={value}>
      {children}
      <div id="google-signin-prompt" style={{ display: 'none' }}></div>
    </GoogleAuthContext.Provider>
  );
};

// Add type definition for Google API
declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (config: any) => void;
          prompt: (callback: (notification: any) => void) => void;
          renderButton: (element: HTMLElement, options: any) => void;
          revoke: (userId: string, callback: () => void) => void;
          disableAutoSelect: () => void;
        }
      }
    }
  }
}
